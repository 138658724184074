export default {
  http: {
    url: 'https://dayakapps.herokuapp.com',
  },
  locale: 'id',
  err: null,
  page: {
    routes: {
      '/': 'index',
      '/elements': 'index',
      '/data/:title/:type': 'data',
      '/sekolah/:id': 'sekolah',
      '/sekolah/:id/guru': 'guru',
      '/sekolah/:id/ptk': 'ptk',
      '/sekolah/:id/rombel': 'rombel',
      '/sekolah/:id/lkg': 'lkg',
      '/sekolah/:id/plan': 'plan',
      '/sekolah/:id/rombel/:rid': 'rombel-detail',
      '/sekolah/:id/klasifikasi': 'klasifikasi-rombel',
      '/sekolah/:id/siswa/:sid': 'siswa',
      '/mapel/materi/upload': 'upload',
      '/mapel/materi/:id': 'materi',
      '/mapel/soal': 'upload',
      '/sekolah/:id/mapel': 'mapel',
      '/sekolah/:id/mapel/:mid': 'mapel',
    },
    default: '404',
    err404: '404',
    header: 'header',
    footer: 'footer',
  }
}