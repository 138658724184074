import { module } from 'ng-starter-pack'
import config from '../config'
import pages from '../pages'

module.run(['$http', '$rootScope', '$window', function ($http, $rootScope, $window) {
  String.prototype.send = function (params) {
    let token = 'development'
    if ($rootScope.account) {
      token = $rootScope.account.token
    }
    return new Promise((resolve, reject) => {
      $http.post(
        config.http.url, {
        to: [],
        action: this,
        params: params || {}
      }, {
        headers: {
          'content-type': 'application/json',
          'token': token
        }
      }
      )
        .then(resp => resp.data)
        .then(resp => {
          if (resp.status) {
            resolve(resp.response.data)
          } else {
            if (resp.code === 'AUTH0') {
              localStorage.removeItem('account')
              delete $rootScope.account
              window.location.href = '/expired'
            }
            reject(resp)
          }
        })
        .catch(err => {
          console.log(err)
          reject({
            status: false,
            message: 'Tidak dapat terhubung dengan server. Silahkan periksa koneksi internet kamu'
          })
        })
    })
  }

  Object.prototype.get = function () {
    if (this.id) {
      'objects.get'
        .send({ id: this.id })
        .then(resp => {
          Object.assign(this, resp)
        });
    }
  }

  Object.prototype.put = function (callback) {
    'objects.put'
      .send(this)
      .then(resp => {
        Object.assign(this, resp)
        return resp
      })
      .then(resp => {
        if (typeof callback === 'function') {
          callback(resp)
        }
      })
      .catch(err => {
        callback(err)
      })
  }

  Object.prototype.store = function () {
    return {
      set: () => {
        localStorage.setItem(this.id, JSON.stringify(this))
      },
      get: () => {
        Object.assign(this, JSON.parse(localStorage.getItem(this.id)) || {})
      }
    }
  }

  Object.prototype.as = function (param) {

    let action = param
    let params = typeof param === 'string' ? { id: this.id } : param || {}

    if (param.id) {
      action = param.action
    }
    if (param.action) {
      action = param.action
      params = param || {}
    }

    action
      .send(params)
      .then(resp => {
        Object.assign(this, resp)
        return this
      });
  }

  Array.prototype.as = function (param) {
    if($rootScope.account === null) return this
    
    let action = 'objects.gets'
    let params = typeof param === 'string' ? { type: param } : param || {}

    if (param.id) {
      action = param.action
    }
    if (param.action) {
      action = param.action
      params = param || {}
    }

    action
      .send(params)
      .then(resp => {
        Object.assign(this, resp)
        return resp
      });
  }

  Array.prototype.store = function () {
    return {
      set: (type) => {
        localStorage.setItem(type, JSON.stringify(this))
      },
      get: (type) => {
        return JSON.parse(localStorage.getItem(type)) || []
      }
    }
  }

  $window.onscroll = function (e) {
    if (window.scrollY > 64) {
      $rootScope.showHeader = $rootScope.scrollY < window.scrollY
      $rootScope.scrollY = window.scrollY
      $rootScope.ddm = false
      $rootScope.$evalAsync();
    }
  };

  $window.onclick = function (e) {
    if (e.target.id !== 'user') {
      $rootScope.ddm = false;
      $rootScope.$evalAsync();
    }
  }

  $rootScope.switchMenu = function () {
    $rootScope.ddm = !$rootScope.ddm
    $rootScope.$evalAsync();
  }

  $rootScope.account = JSON.parse(localStorage.getItem('account'))
  $rootScope.user = $rootScope.account ? pages.user : '';
  $rootScope.home = $rootScope.account ? pages.home : pages.login;

  $rootScope.scrollY = 0
  $rootScope.ddm = false
  $rootScope.materi = []
  $rootScope.ebook = []
  $rootScope.mapels = []
  $rootScope.guru = []
  $rootScope.rombel = []
  $rootScope.news = []

  $http.get('https://newsapi.org/v2/top-headlines?country=id&category=science&apiKey=91098305c46d47fdaacf3f9c9829f80c')
    .then(resp => resp.data)
    .then(resp => {
      $rootScope.news = resp.articles
    })
}])
