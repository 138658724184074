import {module} from 'ng-starter-pack'

module.controller('account', ['$scope','$rootScope', 
  function($scope, $rootScope) {
    $scope.valid = 0;
    const msg = 'Untuk menjaga kerahasiaan pergunakan minimum 8 karakter, kombinasi huruf dan angka atau simbol';
    $scope.message = msg;

    $scope.isValid = function () {
      return $scope.valid === 3;
    } 
    
    $scope.checkNp = function () {
      var validator = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})");

      if(validator.test($scope.np)) {
        $scope.valid = 2;
        $scope.err = false;
        $scope.message = 'Sebagai verifikasi, silahkan masukan kembali password baru' 

        if($scope.np === $scope.cp) {
          $scope.err = false;
          $scope.message = 'Password baru anda sudah layak. Silahkan klik Ganti Password untuk melanjutkan'
        }else{
          $scope.err = true;
          $scope.message = 'Sebagai verifikasi, silahkan masukan kembali password baru' 
        }
      }else{
        $scope.err = true;
        $scope.message = msg;
      }
    } 

    $scope.checkOp = function () {
      'account.check'
        .send({
          op: $scope.op
        })
        .then(resp=>{
          $scope.err = false;
          $scope.message = resp.message 
          $scope.valid = 1; 
        }) 
        .catch(err=>{
          $scope.err = true;
          $scope.message = err.message  
        })
    }

    $scope.chp = function () {
      'account.chp'
        .send({
          op: $scope.op,
          np: $scope.np,
          cp: $scope.cp,
        })
        .then(resp=>{
          $scope.err = false;
          $scope.message = resp.message 
          $scope.valid = 0;
          $scope.op = ''; 
          $scope.np = ''; 
          $scope.cp = ''; 
        }) 
        .catch(err=>{
          $scope.err = true;
          $scope.message = err.message  
        })
    }


  }
])