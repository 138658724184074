import {start} from 'ng-starter-pack' // start = starter to load configuration, module = angular default module
import config from './config' // don't remove this unless you provide configuration here
import pages from './pages' // don't remove this
import './app' // don't remove this
// import 'bulma'
import './style/base.scss' // base style using SCSS
import './assets/font/cerapro' // custom font assets


start(
  Object.assign(config, {
    template: pages
  })
)

