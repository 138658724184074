import {
  module
} from 'ng-starter-pack'
import pages from '../pages'
import moment from 'moment'
import md5 from 'md5'

module.controller('admin', ['$scope', '$rootScope',
  function ($scope, $rootScope) {
    // $scope.selectedRombel = '';

    $scope.ptk = [
      "Guru BK",
      "Guru Mapel",
      "Guru TIK",
      "Kepala Sekolah",
      "Tenaga Administrasi Sekolah",
      "Tenaga Perpustakaan"
    ]
    $scope.mapels = []
    $scope.pegawai = []
    $scope.siswas = []
    $scope.kalender = []
    $rootScope.bankSoal = []
    $rootScope.objKisi = []
    $scope.ms = {}
    $scope.showDataSiswa = true;

    $scope.showMatpel = false;
    $scope.optionLkg = [{
      id: 1,
      desc: 'Penilaian Sikap',
      type: 'catatan'
    }, {
      id: 2,
      desc: 'Penilaian Tugas',
      type: 'evaluasi'
    }, {
      id: 3,
      desc: 'Penilaian Lisan',
      type: 'evaluasi'
    }, {
      id: 4,
      desc: 'Penilaian Keterampilan',
      type: 'evaluasi'
    }, {
      id: 5,
      desc: 'Remedial',
      type: 'evaluasi'
    }, {
      id: 6,
      desc: 'Analisis Hasil Belajar',
      type: 'evaluasi'
    }];
    $rootScope.fieldSiswa = [{
      key: "nama",
      type: "text"
    }]
    // $scope.mapels.as('mapel')
    $scope.mapels.as({
      action: 'sekolah.mapels'
    })
    // console.log($scope.mapels)
    // console.log($rootScope.account.token)
    $scope.loadGuru = function (id) {
      $scope.ms[id] = {
        "7": {
          guru: []
        },
        "8": {
          guru: []
        },
        "9": {
          guru: []
        },
      }
      'sekolah.mapel'
      .send({
          id: id
        })
        .then(resp => {
          if (resp[id]) {
            $scope.ms = resp;
          }
        })
    }

    $scope.loadKKM = function (id) {
      $scope.ms[id]["7"].kkm = 0
      $scope.ms[id]["8"].kkm = 0
      $scope.ms[id]["9"].kkm = 0 
      'sekolah.mapel'
        .send({
          id: id
        })
        .then(resp => {
          if (resp[id]) {
            $scope.ms = resp;
          }
        })
    }

    if ($rootScope.app.params.id) {
      $rootScope.guru.as({
        action: 'sekolah.guru'
      })
      $scope.pegawai.as({
        action: 'sekolah.pegawai'
      })
      console.log($rootScope.app.page)
      if ($rootScope.app.page === 'klasifikasi-rombel') {
        $scope.siswas.as({
          action: 'sekolah.siswas'
        })
        console.log($scope.siswas)
      }
    }

    if ($rootScope.app.params.rid) {
      $rootScope.rombel = {
        id: $rootScope.app.params.rid
      }
      $rootScope.rombel.as('sekolah.rombel')
      $scope.isWali = ($rootScope.account.groups.map((o) => o.id).indexOf($rootScope.rombel.id) >= 0);
    }    
    $scope.changeWali = function () {
      // console.log($rootScope.rombel)    
      // console.log($rootScope.account.sekolah.rombel)   
      let obj = {}
      Object.assign(obj, $scope.item)
      console.log(obj)       
      let rombelAccount = $rootScope.account.sekolah.rombel.map((o) => o.id).indexOf(obj.id)
      console.log(rombelAccount)
      $rootScope.account.sekolah.rombel[rombelAccount].pic = obj.selectedWali
      $rootScope.rombel.pic = obj.selectedWali
      $rootScope.account.sekolah.rombel.put()
      $rootScope.rombel.put()

      
      $scope.modal.show = false
      $rootScope.item = {}
      // console.log($rootScope.account)
      // console.log($rootScope.guru)
    }

    // console.log($rootScope.rombel)
    // console.log($scope.isWali)

    if ($rootScope.app.params.sid) {
      $scope.siswa = {
        id: $rootScope.app.params.sid
      }
      $scope.siswa.get()

    }

    if ($rootScope.app.params.mid) {
      // console.log($rootScope.app.params.mid)
      $scope.mapel = {
        id: $rootScope.app.params.mid
      }
      $scope.mapel.get()
      $scope.loadGuru($rootScope.app.params.mid)
      $scope.loadKKM($rootScope.app.params.mid)

      // $rootScope.bankSoal.as({
      //   action: 'sekolah.banksoal',
      //   mapel_id: $rootScope.app.params.mid, 
      // }) 
      // $rootScope.objKisi.as({
      //   action: 'sekolah.objKisi',
      //   mapel_id: $rootScope.app.params.mid, 
      // }) 
      'sekolah.banksoal'
      .send({
          mapel_id: $rootScope.app.params.mid,
        })
        .then(resp => {
          console.log(resp)
          $rootScope.bankSoal = resp
        })
      'sekolah.objkisi'
      .send({
          mapel_id: $rootScope.app.params.mid,
        })
        .then(resp => {
          console.log(resp)
          $rootScope.objKisi = resp
        })
      // console.log($rootScope.objKisi)
    }

    $scope.setMapel = function (mapel) {
      $scope.mapel = mapel;
      $rootScope.soal = {}
      $scope.loadGuru(mapel.id)
      $scope.loadKKM(mapel.id)
      // $rootScope.bankSoal.as({
      //   action: 'sekolah.banksoal',
      //   mapel_id: mapel.id, 
      // }) 
      // $rootScope.objKisi.as({
      //   action: 'sekolah.objKisi',
      //   mapel_id: $rootScope.app.params.mid, 
      // }) 
      'sekolah.banksoal'
      .send({
          mapel_id: mapel.id,
        })
        .then(resp => {
          $rootScope.bankSoal = resp
          console.log(resp)
        })
      'sekolah.objkisi'
      .send({
          mapel_id: mapel.id,
        })
        .then(resp => {
          console.log(resp)
          $rootScope.objKisi = resp
        })
      let url = `/sekolah/${$rootScope.account.sekolah.id}/mapel/${mapel.id}`;
      // console.log($rootScope.objKisi)
      window.history.pushState(null, null, url)
    }

    $scope.setGuruMapel = function () {
      'sekolah.mapel'
      .send($scope.ms)
        .then(resp => {})
    }

    $scope.addGuruMapel = function (kelas, item) {
      $scope.ms[$scope.mapel.id][kelas].guru.push(item.id)
      $scope.setGuruMapel()
    }

    $scope.delGuruMapel = function (kelas, item) {
      let idx = $scope.ms[$scope.mapel.id][kelas].guru.indexOf(item.id)
      $scope.ms[$scope.mapel.id][kelas].guru.splice(idx, 1)
      $scope.setGuruMapel()
    }

    $scope.setAttrMapel = function () {
      // console.log($scope.ms)
      'sekolah.mapel'
      .send($scope.ms)
        .then(resp => {})
    }
    $scope.setAttrSiswa = function (siswa) {
      $scope.siswa = siswa;
      $scope.siswa.put()
      // console.log($rootScope.rombel)
      // 'sekolah.siswa'
      //   .send($rootScope.rombel)
      //   .then(resp=>{
      //   })
    }
    $scope.addKkmMapel = function (kelas, item) {
      // console.log(item)
      $scope.ms[$scope.mapel.id][kelas].kkm = item
      $scope.setAttrMapel()
    }

    $scope.addSilabusMapel = function (kelas, item) {
      // console.log($scope.ms[$scope.mapel.id][kelas].silabus.length)
      if (!$scope.ms[$scope.mapel.id][kelas].silabus) {
        $scope.ms[$scope.mapel.id][kelas].silabus = []
      }
      $scope.ms[$scope.mapel.id][kelas].silabus.push(item)
      $scope.setAttrMapel()
    }
    $scope.addRppMapel = function (kelas, item) {
      if (!$scope.ms[$scope.mapel.id][kelas].rpp) {
        $scope.ms[$scope.mapel.id][kelas].rpp = []
      }
      $scope.ms[$scope.mapel.id][kelas].rpp.push(item)
      $scope.setAttrMapel()
    }
    $scope.removeSilabusMapel = function (kelas, item) {
      // console.log(item)
      // let idx = $scope.ms[$scope.mapel.id][kelas].silabus.indexOf(item.public_id)
      let idx = $scope.ms[$scope.mapel.id][kelas].silabus.map((o) => o.public_id).indexOf(item.public_id)
      $scope.ms[$scope.mapel.id][kelas].silabus.splice(idx, 1)
      $scope.setAttrMapel()
    }
    $scope.removeRppMapel = function (kelas, item) {
      let idx = $scope.ms[$scope.mapel.id][kelas].rpp.map((o) => o.public_id).indexOf(item.public_id)
      $scope.ms[$scope.mapel.id][kelas].rpp.splice(idx, 1)
      $scope.setAttrMapel()
    }
    $scope.progress = function (percentDone) {
      // console.log("progress: " + percentDone + "%")
    };

    $scope.done = function (files, data, request) {
      // console.log("upload complete")
      // console.log("files: " + JSON.stringify(files)) 
      // console.log("data: " + JSON.stringify(data))  
      // console.log("request: " + JSON.stringify(request))
      var resData = {
        file_name: data.source,
        media_url: data.result.secure_url,
        public_id: data.result.public_id
      }
      // console.log("resData: " + JSON.stringify(resData))
      $scope.addSilabusMapel(request.kelas, resData)
    };    
    $scope.doneRpp = function (files, data, request) {
      var resData = {
        file_name: data.source,
        media_url: data.result.secure_url,
        public_id: data.result.public_id
      }
      $scope.addRppMapel(request.kelas, resData)
    };
    $scope.addRaportSiswa = function (siswa, item) {
      if (!$scope.siswa.raport) {
        $scope.siswa.raport = [];
      }
      $scope.siswa.raport.push(item)
      // console.log(arrSiswa)
      $scope.setAttrSiswa($scope.siswa)
    }
    $scope.removeRaportSiswa = function (siswa) {
      // console.log(siswa)      
      let idx = $scope.siswa.raport.map((o) => o.public_id).indexOf(siswa.public_id)
      $scope.siswa.raport.splice(idx, 1)
      $scope.setAttrSiswa($scope.siswa)
    }
    $scope.doneSiswa = function (files, data, request) {
      var resData = {
        file_name: data.source,
        media_url: data.result.secure_url,
        public_id: data.result.public_id
      }
      // console.log("resData: " + JSON.stringify(resData))
      $scope.addRaportSiswa(request, resData)
    };

    $scope.setData = function (data) {
      //this data will be sent to the server with the files
      return {
        folder: 'pdf',
        kelas: data
      };
    };
    $scope.setDataSiswa = function (data) {
      //this data will be sent to the server with the files
      return {
        folder: 'pdf',
        data: data
      };
    };

    $scope.error = function (files, type, msg) {
      console.log("Upload error: " + msg);
      console.log("Error type:" + type);
    }

    $scope.submitLKG = function (type, desc) {
      var arrSiswa = {};
      var type = JSON.parse(type)
      let key = type.type;
      console.log($scope.selectedMapel);
      arrSiswa = $scope.siswa;
      // arrSiswa[key] = []
      if (!arrSiswa[key]) arrSiswa[key] = []
      arrSiswa[key].push({
        created_by: {
          id: $rootScope.account.id,
          nama: $rootScope.account.nama,
          jenis_ptk: $rootScope.account.jenis_ptk,
          jenis_kelamin: $rootScope.account.jenis_kelamin
        },
        type: type.desc,
        mapel: ($scope.selectedMapel) ? JSON.parse($scope.selectedMapel) : {},
        desc: desc,
        created_at: new Date().getTime(),
      });
      $scope.selectedOptLKG = ""
      $scope.descLKG = ""
      $scope.selectedMapel = ''
      $scope.showMatpel = false;
      // console.log(arrSiswa)
      $scope.setAttrSiswa(arrSiswa)
    }
    $scope.showMapel = function (option) {
      option = JSON.parse(option)
      // console.log(option)
      if (option && option.type != 'catatan') {
        $scope.showMatpel = true;
      } else {
        $scope.showMatpel = false;
      }
    }


    $scope.setAbsensi = function () {
      'sekolah.absensi'
      .send({
          id: $scope.siswa.id,
          created: {
            at: new Date().getTime(),
            id: $rootScope.account.id,
            nama: $rootScope.account.nama
          },
          date: moment().format("DD-MM-YYYY")
        })
        .then(resp => {
          if (resp.message) {
            alert(resp.message)
          } else {
            console.log(resp)
            if (!$scope.siswa.absensi) $scope.siswa.absensi = []
            $scope.siswa.absensi.push(resp)
          }
        })
    }

    $scope.addGuru = function () {
      let obj = {}
      Object.assign(obj, $scope.data_guru)
      if (obj.jenis_ptk.toLowerCase().contains('guru')) {
        obj.type = 'guru'
      } else if (obj.jenis_ptk.toLowerCase().contains('kepala')) {
        obj.type = 'kepala_sekolah'
      } else {
        obj.type = 'ptk'
      }

      obj.tanggal_lahir = moment(obj.tgl_lahir).format("YYYY-MM-DD")
      obj.tgl_lahir = moment(obj.tgl_lahir).format("YYYY-MM-DD")
      obj.sekolah_id = $rootScope.account.sekolah.id
      obj.login = {
        password: md5(moment(obj.tgl_lahir).format("YYYYMMDD"))
      }
      obj.put(resp => {
        console.log(resp)
        $scope.guru.push(resp)
        $scope.modal.show = false
      })
    }

    $rootScope.modal = {
      show: false,
      close: function () {
        $rootScope.item = {}
        $scope.modal.show = false
        console.log($scope.selectedRombel)
      },
      open: function (form, value, idx, remove) {
        $rootScope.item = {}
        console.log(value.rombel)
        $scope.selectedRombel = JSON.stringify($rootScope.account.sekolah.rombel.filter(obj => {
          return obj.nama == value.rombel
        })[0], function (key, value) {
          if (key === "$$hashKey") {
            return undefined;
          }
          return value;
        });
        console.log($scope.selectedRombel)
        if (value.id !== undefined) {
          $scope.showDataSiswa = false
          value.oldRombel = value.rombel
          value.tgl_lahir = new Date()
        } else {
          $scope.showDataSiswa = true
        }
        Object.assign($rootScope.item, value)
        // console.log($scope.selectedRombel)
        console.log($rootScope.item)
        $rootScope.modal.content = (pages[form]) ? pages[form] : ''
        // console.log($rootScope.modal.content)
        $rootScope.modal.show = true
        $rootScope.modal.remove = value.id !== undefined
        if (remove) {
          $rootScope.modal.remove = false;
        }
        $scope.idx = idx
      },
      delete: function () {
        console.log($scope.item.data)
        let obj = {}
        Object.assign(obj, $scope.item.data)
        obj.del(resp => {
          $scope.list.data.splice($scope.idx, 1)
          $scope.modal.show = false
        })
      },
      save: function () {
        $scope.selectedRombel = JSON.parse($scope.selectedRombel)

        let obj = {}
        Object.assign(obj, $scope.item)
        console.log(obj)
        let is_new = obj.id === undefined;

        // $scope.siswa = {
        //   id: obj.id
        // }
        // $scope.siswa.get()
        if (is_new) {
          obj.nik = ''
          obj.sekolah_id = $rootScope.account.sekolah.id
          obj.rombel = $scope.selectedRombel.nama
          obj.tanggal_lahir = moment(obj.tgl_lahir).format("YYYY-MM-DD");
          obj.tgl_lahir = moment(obj.tgl_lahir).format("YYYY-MM-DD");
          obj.login = {
            password: md5(moment(obj.tgl_lahir).format("YYYYMMDD"))
          }
          console.log(obj)
          $scope.siswas.push(obj)
          'sekolah.add_siswa_upload'
          .send(obj)
            .then(resp => {
              console.log(resp)
            })
          // $scope.setAttrSiswa(obj)   
          $scope.modal.show = false
          $scope.selectedRombel.member.push({
            id: obj.id,
            role: 'member'
          })
          $scope.selectedRombel.put()
          $rootScope.item = {}
        } else {
          let checkIsNew = $scope.selectedRombel.member.filter(thisObj => {
            // console.log(thisObj.id)
            return thisObj.id == obj.id
          })
          // console.log(checkIsNew)
          let oldRombel = $rootScope.account.sekolah.rombel.filter(thisObj => {
            return thisObj.nama == obj.oldRombel
          })[0]
          // console.log(oldRombel)

          let idx = oldRombel.member.map((o) => o.id).indexOf(obj.id)
          oldRombel.member.splice(idx, 1)

          if (checkIsNew.length === 0) {
            $scope.selectedRombel.member.push({
              id: obj.id,
              role: 'member'
            })
          }
          obj.rombel = $scope.selectedRombel.nama
          // $scope.siswa.rombel = $scope.selectedRombel.nama        
          oldRombel.put()
          $scope.selectedRombel.put()
          // $scope.setAttrSiswa($scope.siswa)
          $scope.setAttrSiswa(obj)
          // console.log($scope.siswa)
          $scope.modal.show = false
          $scope.siswas.find(someobject => someobject.id == obj.id).rombel = $scope.selectedRombel.nama
          // $scope.siswas.as({
          //   action: 'sekolah.siswas'
          // })    
          // $scope.search = ''
          $rootScope.item = {}
        }
      }
    }

    $scope.doAktivasi = function () {
      $scope.aktivasi = true
      // 'sekolah.aktivasi'
      // .send({})
      //   .then(resp => {
      //     console.log(resp)
      //     // $rootScope.account = resp.data
      //     // localStorage.setItem('account', JSON.stringify(resp.data))
      //     // window.location.href = '/'
      //     $scope.aktivasi = true
      //   })
    }


    $scope.file = {
      valid: function () {
        'sekolah.dapodik'
        .send({})
          .then(resp => {
            console.log(resp)
            // $rootScope.account = resp.data
            // localStorage.setItem('account', JSON.stringify(resp.data))
            // window.location.href = '/'
            $scope.file.err = "File Dapodik tidak Valid. Pastikan anda mengupload file dengan format yang benar"
          })
      }
    }


    $scope.addEvent = function () {
      let data = {}
      Object.assign(data, $scope.event)
      data.type = 'kalender'
      data.sekolah_id = $rootScope.account.sekolah.id
      data.ta = $rootScope.account.sekolah.ta
      data.put(resp => {
        $scope.kalender.push(resp)
        $scope.event = {}
      })

    }
    $scope.editEvent = function (event) {
      $scope.event = {}
      console.log(event)      
      if (event.mulai) {  
        event.mulai = new Date(event.mulai)
      }
      if (event.selesai) {  
        event.selesai = new Date(event.selesai)
      }
      $scope.event = event
    }

    $scope.kalender.as('kalender')

  }

])