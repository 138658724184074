import {
  module
} from 'ng-starter-pack'
import print from 'print-js'
import pages from '../pages'

window.upload = function (obj) {
  let doc = obj.files[0];
  let formData = new FormData();

  formData.append("media", doc);
  formData.append("folder", "sekolah");
  const rs = angular.element(document).injector().get('$rootScope')
  rs.uploading = true;
  rs.$apply()
  fetch('https://dayakapps.herokuapp.com/media', {
      method: "POST",
      body: formData,
      headers: {
        token: 'development'
      }
    })
    .then(resp => resp.json())
    .then(resp => {
      rs.uploading = false
      rs.doneUpload(resp);
      rs.$apply()
    });
}

module.controller('elearning', ['$scope', '$rootScope',
  function ($scope, $rootScope) {
    $scope.upload = {}
    $rootScope.soal = {}
    $scope.item = {}
    $scope.limit = 6
    $scope.filename = ''
    $scope.incLimit = function () {
      $scope.limit += 6
    }


    if ($rootScope.app.params.id) {
      $scope.item = {
        id: $rootScope.app.params.id
      }

      $scope.item.get()
      $scope.comment = ''
      $scope.addComment = function () {
        if (!$scope.item.comments) {
          $scope.item.comments = []
        }
        let comment = {
          id: $scope.item.id,
          created: new Date().getTime(),
          user: {
            id: $rootScope.account.id,
            nama: $rootScope.account.nama,
            type: $rootScope.account.type,
          },
          comment: $scope.comment
        }

        $scope.comment = ''
        $scope.item.comments.push(comment)
        'materi.comment'
        .send(comment)
      }
    } else {
      $rootScope.materi.as('materi')
      $rootScope.mapels.as('mapel')
      $rootScope.ebook.as('ebook')
    }


    $scope.editSoal = function (soal) {
      $rootScope.soal  = soal;
    }

    $scope.addSoal = function () {

      if ($rootScope.soal.id) {
        const idx = $scope.bankSoal.map(el => el.id).indexOf($rootScope.soal.id)
        $rootScope.soal.put()
        $rootScope.soal = {}

      } else {
        $rootScope.soal.type = 'soal';
        $rootScope.soal.sekolah_id = $rootScope.account.sekolah.id;
        $rootScope.soal.mapel_id = window.location.pathname.split('/').reverse()[0] //$rootScope.app.params.mid;
        $rootScope.soal.created_at = new Date().getTime();
        $rootScope.soal.created_by = $rootScope.account.id;
        $rootScope.soal.put((resp) => {
          $rootScope.bankSoal.push(resp)
          $rootScope.soal = {}
        })
      }
    }

    $scope.addKisi = function () {
      // const isKelas = $scope.objKisi.map(el => el.kelas).indexOf($scope.kisi.kelas)
      // console.log(isKelas)
      if ($scope.kisi.id) {
        const idx = $scope.objKisi.map(el => el.id).indexOf($scope.kisi.id)
        $scope.kisi.put()
        $scope.kisi = {}
        // }
        // else if (isKelas > -1) {           
        //   console.log($scope.kisi)    
        //   $scope.kisi.put()
      } else {
        console.log($scope.kisi)
        $scope.kisi.type = 'kisi';
        $scope.kisi.sekolah_id = $rootScope.account.sekolah.id;
        $scope.kisi.mapel_id = window.location.pathname.split('/').reverse()[0] // $rootScope.app.params.mid;
        $scope.kisi.created_at = new Date().getTime();
        $scope.kisi.created_by = $rootScope.account.id;
        $scope.kisi.put((resp) => {
          $rootScope.objKisi.push(resp)
          $scope.kisi = {}
        })
      }
    }
    $scope.editKisi = function (kisi) {
      $scope.kisi = kisi;
    }

    $rootScope.uploading = false
    $rootScope.doneUpload = function (resp) {
      console.log(resp)
      $rootScope.uploading
      $scope.filename = `${resp.source} \u2705`
      $scope.upload.media = resp.result;
      $scope.$evalAsync();
    }

    $scope.uploadMateri = function () {
      $scope.upload.type = 'materi';
      let obj = {}
      Object.assign(obj, $scope.upload)
      console.log(obj)
      obj.tags = obj.tags.split(",")
      obj.media_url = obj.media.secure_url
      obj.sekolah_id = $rootScope.account.sekolah_id
      obj.content_type = obj.media.format
      obj.put(resp => {
        console.log(resp)
        window.location.href="/"
      })
    }


    $scope.exportSoal = function () {

      let obj = {}
      Object.assign(obj, $scope.item)
      console.log($rootScope.bankSoal)
      console.log(obj)
      let getSoal = $rootScope.bankSoal.filter(thisObj => {
        return (thisObj.kelas == obj.exportTingkat && 
          thisObj.semester == obj.exportSemester && 
          thisObj.jenis == obj.jenis)
      })
      let pg = getSoal.filter(thisObj => {
        return (thisObj.pilihan == true)
      })
      let es = getSoal.filter(thisObj => {
        return (thisObj.pilihan != true)
      })
      console.log({
        pg,
        es
      })
      // let es = []
      // let pg = []
      if (obj.countSoal != 'all') {
        if (obj.countSoal <= pg.length) {
          pg.slice(0, obj.countSoal)
        }
        if (obj.countSoal <= es.length) {
          es.slice(0, obj.countSoal)
        }
      }
      pg.sort(function (a, b) {
        return ('' + a.kategori).localeCompare(b.kategori);
      })
      es.sort(function (a, b) {
        return ('' + a.kategori).localeCompare(b.kategori);
      })
      // for(let i = 0; i<$rootScope.bankSoal.length;i++ ) {
      //   let soal = $rootScope.bankSoal[i]

      //   if(soal.pilihan) {
      //     pg.push(soal)
      //   }else{
      //     es.push(soal)
      //   }
      // }

      let content = "";

      const doc = document.getElementById('print')
      content = `<center><h1>${obj.jenis}</h2></center>`;
      if (pg.length > 0) {
        content += '<h2>Soal Pilihan</h2>';
        content += '<i>Pilihlah salah satu jawaban yang tepat untuk pertanyaaan dibawah ini.</i>';
        content += '<ol>';
        for (let i = 0; i < pg.length; i++) {
          if(pg[i].kategori){
            content += `<i><h4>${pg[i].kategori}</h4></i>`
          }
          content += '<li>';
          content += '<span>' + pg[i].deskripsi + '</span>';
          content += '<ol type="A">';
          content += '<li>' + pg[i].jawaban.a + '</li>';
          content += '<li>' + pg[i].jawaban.b + '</li>';
          content += '<li>' + pg[i].jawaban.c + '</li>';
          content += '<li>' + pg[i].jawaban.d + '</li>';
          content += '</ol>';
          content += '</li>';
        }
        content += '</ol>';
      }
      content += '<br/>';
      content += '<hr/>';
      if (es.length > 0) {
        content += '<h2>Soal Isian</h2>';
        content += '<i>Isilah jawaban dari pertanyaan dibawah ini dengan lengkap dan jelas.</i>';
        content += '<ol>';
        for (let i = 0; i < es.length; i++) {
          if(es[i].kategori){
            if(i == 0 || ( i != 0 && es[i].kategori != es[i-1].kategori)){
              content += `<i><h4>${es[i].kategori}</h4></i>`
            }
          }
          content += '<li>';
          content += '<span>' + es[i].deskripsi + '</span>';
          content += '</li>';
        }
        content += '</ol>';
      }
      // console.log(content)
      doc.innerHTML = content

      print('print', 'html')
    }

  }
])