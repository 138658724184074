import { module } from 'ng-starter-pack'
import {Workbook} from 'exceljs'


window.handleImport = function(e, target) {
  const wb = new Workbook();
  const reader = new FileReader()
  const file = e.target.files[0]
  const rs = angular.element(document).injector().get('$rootScope')
  rs.uploading = true;
  rs.$apply()
  reader.readAsArrayBuffer(file)
  reader.onload = () => {
    const buffer = reader.result;
    wb.xlsx.load(buffer).then(workbook => {
      workbook.eachSheet((sheet, id) => {
        sheet.eachRow((row, rowIndex) => {
          const cells = row.values
          rs.importCell(target,cells);
          rs.$apply()
        })
      })
    })
  }
}

module.controller('plan', ['$scope', '$rootScope',
  function ($scope, $rootScope) {
    $scope.process = false
    $scope.aktivasi = {}
    $scope.jadwal = []

    
    $scope.data = {
      type: 'aktivasi',
      ptk: [],
      siswa: [],
      rombel: []
    }

    $rootScope.importCell = function(target,obj) {
      $scope.data[target].push(obj)
    }

    $scope.uploadAktivasi = function() {
      $scope.process = true
      $scope.data.put(resp=>{
        if(resp) {
          $scope.process = resp.status
          $scope.message = resp.message
          $scope.data = {
            type: 'aktivasi',
            ptk: [],
            siswa: [],
            rombel: []
          }
        }
      })
    }

  }
]);