import {module} from 'ng-starter-pack'
import config from '../config'
import pages from '../pages'

module.controller('auth', ['$scope','$rootScope',
  function($scope, $rootScope){
    $scope.doLogin = function(){
       $scope.auth.working = true
      'account.auth'
        .send(Object.assign($scope.auth, {data: $scope.entry}))
        .then(resp=>{
          $scope.auth = Object.assign($scope.auth, resp)
          $scope.entry = null          
          $scope.auth.err = false
          if(resp.step === 'done') {
            $rootScope.account = resp.data
            localStorage.setItem('account', JSON.stringify(resp.data))
            window.location.href = '/'
          }
        })
        .catch(err=>{
          $scope.entry = null
          $scope.auth.err = true
          $scope.auth.message = err.message
        })
        .finally(()=>{
          $scope.auth.working = false
        })
    }

    $scope.doEntry = function(e){
      if(e.keyCode === 13) {
        $scope.doLogin();
      }
    }

    $scope.expired = window.location.pathname === '/expired' ? 'NIP anda dipergunakan pada komputer lain.' : null;
    $scope.reset = function(){
      $scope.auth = {
        step: 1,
        working: false,
        message: 'Gunakan NISN atau NIP kamu untuk menggunakan aplikasi ini',
        hint: 'Username, NISN atau NIP',
        input: 'text',
      }
    }

    $scope.doSignOut = function(){
      localStorage.removeItem('account')
      delete $rootScope.account
      $scope.reset() 
      window.location.href = '/ '
    }

    $scope.reset()
  }
])