import { module } from 'ng-starter-pack'
import pages from '../pages'
import moment from 'moment';

module.filter('bapakIbu', [function () {
  return function (input, format) {
    if (input) {
      return input === 'L' ? 'Bapak' : 'Ibu';
    } else {
      return ''
    }
  }
}])

module.filter('nextYear', [function () {
  return function (input, format) {
    if (input) {
      var current = input.split("/").reverse()[0]
      current = current + "/" + (parseInt(current) + 1)
      return current;
    } else {
      return ''
    }
  }
}])

module.filter('sex', [function () {
  return function (input, format) {
    if (input) {
      return input === 'L' ? 'Laki-laki' : 'Perempuan';
    } else {
      return ''
    }
  }
}])

module.filter('sum', [function () {
  return function (input, expr) {
    if (typeof input === 'array') {
      console.log(expr)
    } else {
      return ''
    }
  }
}])

module.filter('range', [function () {
  return function (input) {
    if (Array.isArray(input)) {
     if(input[1]) {
       if(input[0] === input[1]) return moment(input[0]).format('D MMMM YYYY')
       if(moment(input[0]).format("YYMM") === moment(input[0]).format("YYMM")) 
        return moment(input[0]).format('D') + '-' + moment(input[1]).format('D MMMM YYYY')

     }else{
       return moment(input[0]).format('D MMMM YYYY')
     }
    } else {
      return ''
    }
  }
}])

module.filter('adaTidak', [function () {
  return function (input) {
    if (typeof input === 'array' && input.length > 0) {
      return 'Ada'
    } else {
      return 'Belum Disusun'
    }
  }
}])

module.filter('cleanUnderscore', [function () {
  return function (input) {
    if (input) {
      return input.replace(/_/g, " ");
    } else {
      return ''
    }
  }
}])

module.filter('notIn', [function () {
  return function (input, param) {
    let output = input.filter(el => {
      return param.indexOf(el.id) === -1
    })
    return output
  }
}])

module.filter('in', [function () {
  return function (input, param) {
    let output = input.filter(el => {
      return param.indexOf(el.id) > -1
    })
    return output
  }
}])

module.filter('space', [function () {
  return function (input) {
    if (input) {
      input = input.toLowerCase()
      return input.replace(/ /g, "-");
    } else {
      return ''
    }
  }
}])

module.filter('youtube', [function () {
  return function (input) {
    if (input) {
      let result = input
      if (result.contains('youtu')) {
        result = new URL(result).searchParams.get('v')
        result = result || new URL(result).pathname
        result = `https://i.ytimg.com/vi/${result}/hqdefault.jpg`
      }
      return result;
    } else {
      return ''
    }
  }
}])

function mediaType(input) {
  switch (true) {
    case input.contains('youtu'):
      let id = new URL(input).searchParams.get('v')
      id = id || new URL(input).pathname
      return {
        media: 'Materi Multimedia',
        src: 'youtube',
        cover: `https://i.ytimg.com/vi/${id}/hqdefault.jpg`,
        url: `https://www.youtube.com/embed/${id}`
      };

    case input.contains('.mp4'):
    case input.contains('.ogg'):
    case input.contains('.webm'):
      return {
        media: 'Materi Multimedia',
        src: 'hosted',
        url: input,
        cover: input,
        type: input.split('.').reverse()[0]
      };

    default:
      return {
        media: 'Materi Teks',
        src: 'docs',
        url: input,
        cover: input,
        type: input.split('.').reverse()[0],
      };
  }
}



module.filter('mediaType', [function () {
  return function (input) {
    if (input) {
      let result = mediaType(input)
      return result.media;
    } else {
      return ''
    }
  }
}])

const mediaTemplate = {
  youtube: '<iframe src="$url" height="400" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  hosted: '<video controls><source src="$url" type="video/$type"></video>',
  docs: '<iframe src="//docs.google.com/viewer?url=$url&embedded=true" height="400" frameborder="0"></iframe>',
}

const mediaCover = {
  youtube: '<img src="$url" alt="" class="cover" />',
  hosted: '<video class="cover"><source src="$url" type="video/$type"></video>',
  docs: '<img src="/assets/img/doc.png" alt="" class="cover" />',
}

module.directive('media', [function () {
  return {
    restrict: 'E',
    scope: '=',
    link: function (scope, element, attrs) {
      scope.$watch(
        function (scope) {
          return scope.$eval(attrs.src);
        },
        function (value) {
          if (value && !attrs.cover) {
            const media = mediaType(value)
            const html = mediaTemplate[media.src]
            element.html(html
              .replace('$url', media.url)
              .replace('$type', media.type)
            )
          } else if (value && attrs.cover) {
            const media = mediaType(value)
            const html = mediaCover[media.src]
            element.html(html
              .replace('$url', media.cover)
              .replace('$type', media.type)
            )
          } else {
            element.html('<article></article>')
          }
        }
      )
    }
  }
}])

module.directive('safePaste', [function () {
  var specialCharacters = ["–", "’"],
    normalCharacters = ["-", "'"]
  function replaceInvalidCharacters(string) {
    var regEx;
    for (var x = 0; x < specialCharacters.length; x++) {
      regEx = new RegExp(specialCharacters[x], 'g');
      string = string.replace(regEx, normalCharacters[x]);
    }

    return string;
  }
  function handlePaste(event) {
    event.preventDefault();
    var plainText = event.clipboardData.getData('text/plain');
    var cleanText = replaceInvalidCharacters(plainText);
    document.execCommand('inserttext', false, cleanText);
    return false;
  }
  var declaration = {};

  declaration.restrict = 'A';

  declaration.link = function (scope, element, attr) {
    element.on('paste', handlePaste);
    scope.$on('$destroy', function () {
      element.off('paste', handlePaste);
    });
  };

  return declaration;
}
]);


module.directive('sekolah', [function () {
  return {
    template: pages['sekolah-tpl']
  }
}]);

module.directive('dapodik', [function () {
  return {
    template: `
    <div class="small italic">
      <p>Data yang disajikan disini merupakan data hasil upload dokumen dari Dapodik
        untuk menjaga data integrity antara aplikasi Digital Learning dengan data Dapodik.</p>
    </div>`
  }
}]);
module.directive('ngEnter', function () {
  return function (scope, elem, attrs) {
    elem.bind("keydown keypress", function (event) {
      // 13 represents enter button
      if (event.which === 13) {
        scope.$apply(function () {
          scope.$eval(attrs.ngEnter);
        });
        event.preventDefault();
      }
    });
  };
});
module.directive("ngUploadChange", function () {
  return {
    scope: {
      ngUploadChange: "&"
    },
    link: function ($scope, $element, $attrs) {
      $element.on("change", function (event) {
        $scope.$apply(function () {
          $scope.ngUploadChange({ $event: event })
        })
      })
      $scope.$on("$destroy", function () {
        $element.off();
      });
    }
  }
});
module.factory('fileUploader', ['$rootScope', '$q', function ($rootScope, $q) {
  var svc = {
    post: function (files, data, progressCb) {

      return {
        to: function (uploadUrl) {
          var deferred = $q.defer()
          if (!files || !files.length) {
            deferred.reject("No files to upload");
            return;
          }

          var xhr = new XMLHttpRequest();
          xhr.upload.onprogress = function (e) {
            $rootScope.$apply(function () {
              var percentCompleted;
              if (e.lengthComputable) {
                percentCompleted = Math.round(e.loaded / e.total * 100);
                if (progressCb) {
                  progressCb(percentCompleted);
                } else if (deferred.notify) {
                  deferred.notify(percentCompleted);
                }
              }
            });
          };

          xhr.onload = function (e) {
            $rootScope.$apply(function () {
              var ret = {
                files: files,
                data: angular.fromJson(xhr.responseText),
                request: data
              };
              deferred.resolve(ret);
            })
          };

          xhr.upload.onerror = function (e) {
            var msg = xhr.responseText ? xhr.responseText : "An unknown error occurred posting to '" + uploadUrl + "'";
            $rootScope.$apply(function () {
              deferred.reject(msg);
            });
          }

          var formData = new FormData();

          if (data) {
            Object.keys(data).forEach(function (key) {
              formData.append(key, data[key]);
            });
          }

          for (var idx = 0; idx < files.length; idx++) {
            formData.append('media', files[idx]);
          }

          xhr.open("POST", uploadUrl);
          xhr.setRequestHeader('Authorization', $rootScope.account.token);
          xhr.send(formData);

          return deferred.promise;
        }
      };
    }
  };

  return svc;
}]);
module.factory('uuid', function () {
  var svc = {
    new: function () {
      function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
      }
      return _p8() + _p8(true) + _p8(true) + _p8();
    },

    empty: function () {
      return '00000000-0000-0000-0000-000000000000';
    }
  };

  return svc;
});
module.directive('lvlFileUpload', ['uuid', 'fileUploader', function (uuid, fileUploader) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      chooseFileButtonText: '@',
      chooseFileButtonStyle: '@',
      uploadFileButtonText: '@',
      uploadFileButtonStyle: '@',
      uploadUrl: '@',
      maxFiles: '@',
      maxFileSizeMb: '@',
      autoUpload: '@',
      getAdditionalData: '&',
      onProgress: '&',
      onDone: '&',
      onError: '&',
      onFileAdded: '&'
    },
    template: '<div class="input-file-container">' +
      '<input type="file" class="input-file"  />' +
      '<label class="{{ chooseFileButtonStyle }}" ng-click="choose()"><img class="icon" src="/assets/img/uploading.svg" />' +
      '<span>{{chooseFileButtonText}}</span></label>' +
      '<span class="processUpload" ng-show="processUpload">{{percentProgress}}</span>' +
      '<button class="{{ uploadFileButtonStyle }}" ng-show="showUploadButton" ng-click="upload()">{{uploadFileButtonText}}</button>' +
      '</div>',
    compile: function compile(tElement, tAttrs, transclude) {
      var fileInput = angular.element(tElement.children()[0]);
      var fileLabel = angular.element(tElement.children()[1]);

      if (!tAttrs.chooseFileButtonStyle) {
        tAttrs.chooseFileButtonStyle = 'lvl-choose-button';
      }

      if (!tAttrs.uploadFileButtonStyle) {
        tAttrs.uploadFileButtonStyle = 'lvl-upload-button';
      }

      if (!tAttrs.maxFiles) {
        tAttrs.maxFiles = 1;
        fileInput.removeAttr("multiple")
      } else {
        fileInput.attr("multiple", "multiple");
      }

      if (!tAttrs.maxFileSizeMb) {
        tAttrs.maxFileSizeMb = 50;
      }

      var fileId = uuid.new();
      fileInput.attr("id", fileId);
      fileLabel.attr("for", fileId);

      return function postLink(scope, el, attrs, ctl) {
        scope.files = [];
        scope.showUploadButton = false;
        scope.processUpload = false;
        scope.percentProgress = ''

        el.bind('change', function (e) {
          if (!e.target.files.length) return;

          scope.files = [];
          var tooBig = [];
          if (e.target.files.length > scope.maxFiles) {
            raiseError(e.target.files, 'TOO_MANY_FILES', "Cannot upload " + e.target.files.length + " files, maxium allowed is " + scope.maxFiles);
            return;
          }

          for (var i = 0; i < scope.maxFiles; i++) {
            if (i >= e.target.files.length) break;

            var file = e.target.files[i];
            scope.files.push(file);
            scope.onFileAdded({ file: file });

            if (file.size > scope.maxFileSizeMb * 1048576) {
              tooBig.push(file);
            }
          }

          if (tooBig.length > 0) {
            raiseError(tooBig, 'MAX_SIZE_EXCEEDED', "File lebih besar dari maks yang ditentukan (" + scope.maxFileSizeMb + "MB)");
            return;
          }

          if (scope.autoUpload && scope.autoUpload.toLowerCase() == 'true') {
            scope.upload();
          } else {
            scope.$apply(function () {
              scope.showUploadButton = true;
            })
          }
        });

        scope.upload = function () {
          scope.processUpload = true;
          var data = [];
          if (scope.getAdditionalData) {
            data = scope.getAdditionalData();
          }

          if (angular.version.major <= 1 && angular.version.minor < 2) {
            //older versions of angular's q-service don't have a notify callback
            //pass the onProgress callback into the service
            fileUploader
              .post(scope.files, data, function (complete) { scope.onProgress({ percentDone: complete }); })
              .to('https://dayakapps.herokuapp.com/media')
              .then(function (ret) {
                scope.onDone({ files: ret.files, data: ret.data });
              }, function (error) {
                scope.onError({ files: scope.files, type: 'UPLOAD_ERROR', msg: error });
              })
          } else {
            fileUploader
              .post(scope.files, data)
              .to('https://dayakapps.herokuapp.com/media')
              .then(function (ret) {
                scope.onDone({ files: ret.files, data: ret.data, request: ret.request });
                scope.percentProgress = ''
                scope.processUpload = false;
              }, function (error) {
                scope.onError({ files: scope.files, type: 'UPLOAD_ERROR', msg: error });
              }, function (progress) {
                scope.onProgress({ percentDone: progress });
                console.log(progress)
                scope.percentProgress = 'Memproses ... ' + progress + '%'
              });
          }

          resetFileInput();
        };

        function raiseError(files, type, msg) {
          scope.onError({ files: files, type: type, msg: msg });
          resetFileInput();
        }

        function resetFileInput() {
          var parent = fileInput.parent();

          fileInput.remove();
          var input = document.createElement("input");
          var attr = document.createAttribute("type");
          attr.nodeValue = "file";
          input.setAttributeNode(attr);

          var inputId = uuid.new();
          attr = document.createAttribute("id");
          attr.nodeValue = inputId;
          input.setAttributeNode(attr);

          attr = document.createAttribute("class");
          attr.nodeValue = "input-file";
          input.setAttributeNode(attr);



          if (scope.maxFiles > 1) {
            attr = document.createAttribute("multiple");
            attr.nodeValue = "multiple";
            input.setAttributeNode(attr);
          }

          fileLabel.after(input);
          fileLabel.attr("for", inputId);

          fileInput = angular.element(input);
        }
      }
    }
  }
}]);