import {
  module
} from 'ng-starter-pack'
import pages from '../pages'
import md5 from 'md5'

const coldef = [{
  "for": "sekolah.pegawai",
  "cols": [{
    "key": "nip",
    "label": "NIP"
  }, {
    "key": "nama",
    "label": "Nama"
  }, {
    "key": "jenis_ptk",
    "type": "option",
    "label": "Jabatan",
    "option": ["Guru BK", "Guru Mapel", "Guru TIK", "Kepala Sekolah", "Penjaga Sekolah", "Pesuruh/Office Boy", "Petugas Keamanan", "Tenaga Administrasi Sekolah", "Tenaga Perpustakaan", "Tukang Kebun"]
  }, {
    "key": "tanggal_lahir",
    "type": "date",
    "label": "Tgl. Lahir"
  }, {
    "key": "agama",
    "type": "option",
    "label": "Agama",
    "option": ["Islam", "Kristen", "Buddha", "Katolik", "Hindu", "Khonghucu"]
  }, {
    "key": "jenis_kelamin",
    "type": "option",
    "label": "Jenis Kelamin",
    "option": ["L", "P"]
  }, {
    "key": "role",
    "type": "option",
    "label": "Hak Akses",
    "option": ["Tata Usaha", "Guru"]
  }, {
    "key": "aktif",
    "type": "option",
    "label": "Status",
    "option": ["AKtif", "Tidak Aktif"]
  }, {
    "key": "password",
    "hide": "grid",
    "type": "password",
    "label": "Password"
  }],
  "label": "Daftar Pegawai"
}]
module.controller('adm', ['$rootScope', '$scope',
  function ($rootScope, $scope) {
    $scope.action = ''
    $scope.list = []
    $scope.coldef = coldef[0]
    $scope.ptk = [
      "Guru BK",
      "Guru Mapel",
      "Guru TIK",
      "Kepala Sekolah",
      "Tenaga Administrasi Sekolah",
      "Tenaga Perpustakaan"
    ]
    $rootScope.item = {}
     

    $rootScope.modal = {
      show: false,
      close: function () {
        $scope.modal.show = false
      },
      open: function (field, value, source, action, type, idx, remove) {
        $rootScope.item = {}
        $rootScope.field = {}
        Object.assign($rootScope.item, value)
        Object.assign($rootScope.field, field)
        $scope.action = action
        $scope.type = type
        $rootScope.modal.content = pages['form-guru']
        $rootScope.modal.show = true
        $rootScope.modal.remove = value.id !== undefined
        if (remove) {
          $rootScope.modal.remove = false;
        }
        $scope.idx = idx
      },
      delete: function () {
        console.log($scope.item.data)
        let obj = {}
        Object.assign(obj, $scope.item.data)
        obj.del(resp => {
          $scope.list.data.splice($scope.idx, 1)
          $scope.modal.show = false
        })
      },
      save: function () {
        let obj = {}
        console.log($rootScope.guru)
        Object.assign(obj, $scope.item)
        obj.type = $scope.type
        obj.tanggal_lahir = moment(obj.tgl_lahir).format("YYYY-MM-DD")
        obj.tgl_lahir = moment(obj.tgl_lahir).format("YYYY-MM-DD")
        obj.sekolah_id = $rootScope.account.sekolah.id
        obj.login = {
          password: md5(moment(obj.tgl_lahir).format("YYYYMMDD"))
        }

        // console.log(obj)
        let is_new = obj.id === undefined;
        // obj.put((resp => {
          if (is_new) {
            $rootScope.guru.push(obj)            
            $scope.action.send(obj)
            .then(resp => {
              console.log(resp)
            })   
            
          } else {
            $rootScope.guru[$scope.id] = resp
          }
          $rootScope.item = {}
          $scope.modal.show = false
        // }))
      }
    }
    let app = $rootScope.app;
    let list = $scope.list
    let currentPath = ""
    $rootScope.$watch('app.path', (pathname) => {
      if (currentPath !== pathname) {
        currentPath = pathname
        if (app.params.type) {
          list.as(app.params.type)
        }
      } else {

      }
    })
  }
])