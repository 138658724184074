import { module } from 'ng-starter-pack'

module.directive('calendar', [function () {
  return {
    require: 'ngModel',
    scope: {
      month: '@',
      onselect: '&',
      header: '@',
      short: '@',
    },
    link: function (scope, element, attrs, ngModel) {
      scope.selected = []
      switch (scope.month) {
        case "now":
          scope.ym = moment().format("YYYYMM")
          break;
        case "before":
          scope.ym = moment().add(-1, 'M').format("YYYYMM")
          break;
        case "next":
          scope.ym = moment().add(1, 'M').format("YYYYMM")
          break;
        default:
          scope.ym = scope.month;
          break;
      }
      scope.m = scope.ym.substr(0, 4)
      scope.y = scope.ym.substr(4, 4)
      scope.mm = moment(scope.ym + "01").format(scope.short ? "MMM" : "MMMM")
      {
        const startDate = new Date(moment(scope.ym + "01"));
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        var d = 1;
        var last = startDate.getDay() + endDate.getDate();
        scope.dates = []
        for (var i = 0; i < last; i++) {
          var cd = {}
          if (i >= startDate.getDay() && d <= endDate.getDate()) {
            cd = {
              d: d,
              date: moment(startDate).add(d - 1, 'days').format('YMMDD')
            }
            cd.holiday = i % 7 == 6 || i % 7 == 0;
            d++;
          }
          scope.dates.push(cd)
        }
      }

      scope.onSelect = function (el) {
        const idx = scope.selected.indexOf(el)
        console.log(idx)
        if(idx > -1) {
          scope.selected.splice(idx,0)
          el.selected = false
        } else {
          el.selected = true
          scope.selected.push(el)
        }
        // const r = scope.selected.filter((item,idx) => {
        //   item.selected = scope.selected.indexOf(item) === idx
        //   return item.selected
        // })
        // scope.onselect(scope.selected)
        ngModel.$setViewValue(scope.selected)
        scope.$evalAsync()
      }
    },
    template: `
    <div>
      <header ng-show="header">
        <h4>
          <span ng-bind="mm"></span>
          <span ng-bind="y"></span>
        </h4>
      </header>
      <ul class="calendar">
        <li ng-repeat='day in dates' ng-bind='day.d' ng-click='onSelect(day)'
         ng-class='{"selected": day.selected}'></li>
      </ul>
    </div> 
    `
  }
}]);